import CourseCard from "./CourseCard";
import React, { useState, useEffect } from "react";
import FacultyPortalStyles from "../../../../styles.js";
import styles from "./CourseCardDisplay.styles";
import { connect } from "react-redux";
import NoCurrentCourses from "./NoCurrentCourses.jsx";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../../components/SnackbarContentWrapper";
import {
  getFacultyCurrentCourses,
  getFacultyWorkshops,
} from "../../../../services/faculty/v1/faculty";
import facultyPortalTheme from "../../../../facultyPortalTheme.js";
import { getFeocsInvite } from "../../../../redux/feocsInviteLink/feocsInviteLinkActions";
import { storeErrorMessages } from "../../../../redux/displayApiErrors/displayApiErrorsActions";
import { getStudentAccommodations } from "../../../../redux/studentAccommodations/studentAccommodationsActions";
import { getSectionsByIrn } from "../../../../redux/workshops/workshopActions.js";
import { getExtendedCoursesForIrn } from "../../../../redux/studentAccommodations/studentAccommodationsActions";
import ExtInProgress from "../currentCourses/ExtInProgress.jsx";


const CourseCardDisplay = (props) => {
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const facultyClasses = FacultyPortalStyles();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [ccOpenSuccess, setCcOpenSuccess] = useState(false);
  const [wsSectionsOpenSuccess, setWsSectionsOpenSuccess] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [currentCourseData, setCurrentCourseData] = useState({});
  // const [workshopData, setWorkshopData] = useState([]);
  // const [wsData, setWsData] = useState([]);
  const [ccData, setCcData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [workshopsToDisplay, setWorkshopsToDisplay] = useState([]);
  const [courseOfferingIdList, setCourseOfferingIdList] = useState([]);
  const [studentIrnList, setStudentIrnList] = useState([]);
  const [toggleAccommodations, setToggleAccommodations] = useState(false);

  let courseCardKeyCount = 0;

  useEffect(() => {
    if (props.wsUser && props.demographics.issuerId) {
      handleGetSectionsForIrn(props.demographics.issuerId);
      const toggleUserArray = process.env.REACT_APP_USERNAME_LIST.split(',').map(item => item.trim());
      setToggleAccommodations(toggleUserArray.includes(props.demographics.username.toLowerCase()));
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetSectionsForIrn = (irn) => {
    if (irn) {
      props.getSectionsByIrn(irn);
    }
  };

  function convertToLocalWithoutTimestamp(utcDate) {
    let utc = new Date(utcDate);
    let localDate = new Date(
      utc.toLocaleString(undefined, { timeZone: "UTC" })
    );
    localDate.setHours(0, 0, 0, 0);
    return localDate;
  }

  function addDays(date, daysToAdd) {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + daysToAdd);
    return newDate;
  }

  // new Set workshop sections data
  const setWorkshopSectionsData = (sectionList) => {
    if (sectionList.length > 0) {
      const filtered = sectionList.filter((section) => {
        return (
          section.status === "Scheduled" &&
          convertToLocalWithoutTimestamp(
            addDays(
              new Date(section.startDate),
              -process.env.REACT_APP_WS_REG_DEADLINE_DAYS
            )
          ) <= convertToLocalWithoutTimestamp(new Date()) &&
          convertToLocalWithoutTimestamp(
            addDays(new Date(section.endDate), 7)
          ) >= convertToLocalWithoutTimestamp(new Date())
        );
      });
      setWorkshopsToDisplay(filtered);
    }
  };

  useEffect(() => {
    if (props.demographics.issuerId) {
      fetchCurrentCoursesData();
      props.getFeocsInvite(props.demographics.issuerId);

      if (!props.wsUser) {
        fetchWorkshopData();
      } else {
        setWorkshopSectionsData(props.sectionsForIrn);
      }
    }

    // return () => {
    //   setWsData([]);
    // };
  }, [props.demographics.issuerId, props.sectionsForIrn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const courseList = [];
    if (ccData.length > 0) {
      ccData.map((course) => courseList.push(course));
    }
    if (workshopsToDisplay.length > 0) {
      if (!props.wsUser) {
        workshopsToDisplay.map((course) => courseList.push(course));
      } else {
        workshopsToDisplay.map((course) =>
          courseList.push({
            courseId: course.courseCode,
            courseOfferingId: course.id,
            courseTitle: course.courseTitle,
            description: course.templateDesc,
            endDate: `${course.endDate} 00:00:00`,
            groupId: course.groupId,
            offeringType: "WS",
            rosterId: course.rosterId,
            schedulingRole: course.role,
            status: course.status,
            startDate: `${course.startDate} 00:00:00`,
            capacity: course.capacity,
            facultyName: `${props.demographics.firstName} ${props.demographics.lastName}`,
            accommodatedStudentIrnList: [],
            extendedStudentIrnList: []       
          })
        );
      }
    }
    
    setCourseData([...courseData, ...courseList]);
  }, [workshopsToDisplay, ccData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Below Code deals with retreiving current courses info and the old way of retrieving workshops info
  // -------------------------------------------------------------------------------------------------------------
  let retStatus = 0;
  let data;

  const getCurrentCourses = async (irn) => {
    await getFacultyCurrentCourses(irn)
      .then((response) => {
        retStatus = response.status;
        data = response.data;
        setCurrentCourseData(response);   
      })
      .catch((error) => {
        setCurrentCourseData(error.request);
        retStatus = error.status;
      });
    return { retStatus: retStatus, data: data };
  };

  const fetchCurrentCoursesData = async (event) => {
    // sends the get request
    await getCurrentCourses(props.demographics.issuerId)
      //await getCurrentCourses("fakeIRN")
      .then((response) => {
        if (response.retStatus === 200) {
          setCurrentCourseData(response.data);
          setCcOpenSuccess(false);
          setCcData(response.data);
        } else {
          setCcOpenSuccess(true);
          // setErrorMessage("Current course information not found.");
        }
      });
  };

  useEffect(() => {
    if(props.demographics.issuerId){
      props.getExtendedCoursesForIrn(props.demographics.issuerId);
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (props.extendedCourses !== undefined && props.extendedCourses !== null && !props.extendedCourses.status) {
      if (currentCourseData?.status === 200) {
        handleCreateMasterStudentAndCourseLists(currentCourseData.data, props.extendedCourses);
        setCourseData([...props.extendedCourses]);
      };
    };
  }, [props.extendedCourses, currentCourseData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateMasterStudentAndCourseLists = (currentCourses, extendedCourses) => {
    // Combine both arrays and use a single iteration
    const allCourses = [...currentCourses, ...extendedCourses];
    
    // Use reduce to build both lists in a single pass
    const { courseOfferingIds, studentIrns } = allCourses.reduce((acc, course) => ({
      courseOfferingIds: [...acc.courseOfferingIds, course.courseOfferingId],
      studentIrns: [...acc.studentIrns, ...course.accommodatedStudentIrnList]
    }), { courseOfferingIds: [], studentIrns: [] });
  
    // Remove duplicates from student IRNs using Set
    const uniqueStudentIrns = [...new Set(studentIrns)];
  
    setCourseOfferingIdList(courseOfferingIds);
    setStudentIrnList(uniqueStudentIrns);
  };

  // old getWorkshops
  const getWorkshops = async (profileId) => {
    await getFacultyWorkshops(profileId)
      .then((response) => {
        retStatus = response.status;
        // console.log(response.status);
        data = response.data;
      })
      .catch((error) => {
        // setWorkshopData(error.request);
        retStatus = error.status;
      });
    return { retStatus: retStatus, data: data };
  };

  // old fetch workshop data
  const fetchWorkshopData = async (event) => {
    // console.log(props.demographics[`externalSystemIds`].PROFILE_ID[0]);
    // sends the get request
    await getWorkshops(props.demographics[`externalSystemIds`].PROFILE_ID[0])
      // await getWorkshops("fakeIRN")
      .then((response) => {
        if (response.retStatus === 200) {
          // setWorkshopData(response.data);
          setWsSectionsOpenSuccess(false);
          //setWorkshopSectionsData(response.data);
          setWorkshopsToDisplay(response.data);
        } else {
          setWsSectionsOpenSuccess(true);
          // setErrorMessage("Workshop information not found.");
        }
      });
  };

  
  // Below Code deals with Snackbar
  // -------------------------------------------------------------------------------------------------------------
  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={
          openSuccess
            ? true
            : ccOpenSuccess
            ? true
            : wsSectionsOpenSuccess
            ? true
            : false
        }
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.issuerId) {
      if (ccOpenSuccess) {
        fetchCurrentCoursesData();
      }
      if (wsSectionsOpenSuccess) {
        handleGetSectionsForIrn(props.demographics.issuerId);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  useEffect(() => {
    if (
      currentCourseData.status !== undefined &&
      currentCourseData.status !== 200
    ) {
      props.storeErrorMessages(
        "Current course information not found.",
        "current courses"
      );
    } else {
      setCcOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [currentCourseData.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.sectionsForIrn.status !== undefined &&
      props.sectionsForIrn.status !== 200
    ) {
      props.storeErrorMessages(
        "Workshop Sections information not found.",
        "sectionsForIrn"
      );
    } else {
      setWsSectionsOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [props.sectionsForIrn.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "current courses"
    ) {
      setCcOpenSuccess(true);
      setOpenSuccess(true);
    }
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "sectionsForIrn"
    ) {
      setWsSectionsOpenSuccess(true);
      setOpenSuccess(true);
    }
  }, [props.errorMessages]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------------------------------------------------------------------
  
  // =========================================================================================================================
  // Call get student acknowledgement endpoint.  Create an object to pass in with the get acknowledgement endpoint.
  const [getAcknowledgementObject, setGetAcknowledgementObject] = useState({});

  useEffect(() => {
    if (props.demographics.issuerId !== null || props.demographics.issuerId !== undefined) {
      if (studentIrnList.length > 0) {
        setGetAcknowledgementObject({
          facultyIrn: props.demographics.issuerId,
          studentIrns: studentIrnList,
          courseOfferingIds: courseOfferingIdList,
        })
      }
    }
  }, [props.demographics, courseOfferingIdList, studentIrnList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(getAcknowledgementObject).length > 0) {
      props.getStudentAccommodations(getAcknowledgementObject);
    }
  }, [getAcknowledgementObject]); // eslint-disable-line react-hooks/exhaustive-deps
  // =========================================================================================================================

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      {ccOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
      {wsSectionsOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
      {courseData.length >= 1 ? (
            <>
              {courseData
                .filter((course) => course?.extendedStudentIrnList.length === 0 || course?.offeringType === "WS")
                .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
                .map((currentCourse) => {
    
                  return (
                    <div
                      className={currentCourse.courseId === null ? classes.hide : ""}
                      key={courseCardKeyCount++}
                    >
                      <CourseCard
                        matches={matches}
                        type="courseCardDisplay"
                        currentCourse={currentCourse}
                        wsUser={props.wsUser}
                      />
                    </div>
                  );
                })}
              {toggleAccommodations && (
                <>
                  <Typography 
                    className={matches ? classes.extTitle : classes.extTitleMobile} 
                    variant={matches ? "h2" : "h5"} 
                    component="h2"
                  >
                    {`Extensions in progress`}
                  </Typography>
                  <ExtInProgress />
                </>
              )}
            </>
          ) : (
        <NoCurrentCourses />
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    feocsInviteLink: state.feocsInviteLink,
    sectionsForIrn: state.sectionsForIrn,
    demographics: state.demographics,
    errorMessages: state.errorMessages,
    studentAccommodations: state.studentAccommodations,
    extendedCourses: state.extendedCourses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFeocsInvite: (irn) => dispatch(getFeocsInvite(irn)),
    getSectionsByIrn: (irn) => dispatch(getSectionsByIrn(irn)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
    getStudentAccommodations: (studentAccommodationObject) =>
      dispatch(getStudentAccommodations(studentAccommodationObject)),
    getExtendedCoursesForIrn: (irn) =>
      dispatch(getExtendedCoursesForIrn(irn)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseCardDisplay);