import { React, useState, useEffect } from "react";
import styles from "../styles";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import facultyPortalTheme from "../../../facultyPortalTheme";
import {
  useMediaQuery,
  Box,
  Grid,
  Link,
  Typography,
  TableCell,
  Modal,
} from "@material-ui/core";
import BlackArrow from "../../../images/BlackArrow.svg";
import { connect } from "react-redux";
import CourseTableCard from "./CourseTableCard";
import ActionNeeded from "./ActionNeeded";
import { getStudentAccommodations } from "../../../redux/studentAccommodations/studentAccommodationsActions";
import { getFacultyCurrentCourses } from "../../../services/faculty/v1/faculty";
import { getPersonInfoWithName } from "../../../redux/demographics/demographicsActions";
import { selectPersonByIrnAndType } from "../../../redux/demographics/personsWithNameSelector";
import { getExtendedCoursesForIrn } from "../../../redux/studentAccommodations/studentAccommodationsActions";

const ManageAccommodations = (props) => {
  document.title = "Faculty Portal Manage Accommodations Page";
  const classes = styles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));

  const [courses, setCourses] = useState([]); // Main course list, where each course object will make up a course table
  const [alreadyAcknowledged, setAlreadyAcknowledged] = useState(); // Used for popup. Distinguish between "Reveiw FNF", and new accommodation
  const [update, setUpdate] = useState({
    studentIrn: "",
    courseOfferingId: "",
  }); // Needed for after acknowledgment, page will refresh

  // =========================================================================================================================
  // useState that is used for ActionsNeeded.jsx component and calling its respective POST accommodations endpoint.  Function
  // to close modal.
  const [openModal, setOpenModal] = useState(false);
  const [actionNeededObject, setActionNeededObject] = useState({});

  const handleCloseModal = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setOpenModal(false);
  };
  // =========================================================================================================================

  // =========================================================================================================================
  // Call current courses endpoint and create list of courseOfferingId's and studentIrns, with accommodations, that are within
  // each current course of the faculty.
  const [currentCourses, setCurrentCourses] = useState([]);
  const [allCoursesToDisplay, setAllCoursesToDisplay] = useState([]);
  const [courseOfferingIdList, setCourseOfferingIdList] = useState([]);
  const [studentIrnList, setStudentIrnList] = useState([]);

  let currentCoursesData = [];
  let currentCoursesRetStatus = 0;

  const getCurrentCourses = async (irn) => {
    await getFacultyCurrentCourses(irn)
      .then((response) => {
        currentCoursesData = response.data;
        setCurrentCourses(response);
      })
      .catch((error) => {
        currentCoursesData = error.request;
        currentCoursesRetStatus = error.status;
      });
  };

  useEffect(() => {
    if (props.facultyDemographics.issuerId) {
      getCurrentCourses(props.facultyDemographics.issuerId);
      props.getExtendedCoursesForIrn(props.facultyDemographics.issuerId);
    }
  }, [props.facultyDemographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.extendedCourses !== undefined &&
      props.extendedCourses !== null &&
      !props.extendedCourses.status
    ) {
      if (currentCourses?.status === 200) {
        handleCreateMasterStudentAndCourseLists(
          currentCourses.data,
          props.extendedCourses
        );
      }
    }
  }, [props.extendedCourses, currentCourses]);
  const handleCreateMasterStudentAndCourseLists = (
    currentCourses,
    extendedCourses
  ) => {
    // Combine both arrays and use a single iteration
    const allCourses = [...currentCourses, ...extendedCourses];
    setAllCoursesToDisplay([...currentCourses, ...extendedCourses]);

    // Use reduce to build both lists in a single pass
    const { courseOfferingIds, studentIrns } = allCourses.reduce(
      (acc, course) => ({
        courseOfferingIds: [...acc.courseOfferingIds, course.courseOfferingId],
        studentIrns: [...acc.studentIrns, ...course.accommodatedStudentIrnList],
      }),
      { courseOfferingIds: [], studentIrns: [] }
    );

    // Remove duplicates from student IRNs using Set
    const uniqueStudentIrns = [...new Set(studentIrns)];

    setCourseOfferingIdList(courseOfferingIds);
    setStudentIrnList(uniqueStudentIrns);
  };
  // =========================================================================================================================

  // =========================================================================================================================
  // Call get student acknowledgement endpoint.  Create an object to pass in with the get acknowledgement endpoint.
  const [getAcknowledgementObject, setGetAcknowledgementObject] = useState({});

  useEffect(() => {
    if (
      props.facultyDemographics.issuerId !== null ||
      props.facultyDemographics.issuerId !== undefined
    ) {
      if (studentIrnList.length > 0) {
        setGetAcknowledgementObject({
          facultyIrn: props.facultyDemographics.issuerId,
          studentIrns: studentIrnList,
          courseOfferingIds: courseOfferingIdList,
        });
      }
    }
  }, [props.facultyDemographics, courseOfferingIdList, studentIrnList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(getAcknowledgementObject).length > 0) {
      props.getStudentAccommodations(getAcknowledgementObject);
    }
  }, [getAcknowledgementObject, update]); // eslint-disable-line react-hooks/exhaustive-deps
  // =========================================================================================================================

  // =========================================================================================================================
  // Use personName endpoint to create a list of persons for each student, using studentIrnList
  const [studentFullName, setStudentFullName] = useState("");
  const [studentPersonId, setStudentPersonId] = useState("");

  const studentPersonType = "student";

  const handleGetPersonInfoWithName = (irn, personType) => {
    props.getPersonInfoWithName(irn, personType);
  };

  useEffect(() => {
    for (let i = 0; i < studentIrnList.length; i++) {
      handleGetPersonInfoWithName(studentIrnList[i], studentPersonType);
    }
  }, [studentIrnList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.student && props.student.irn) {
      const studentPerson = selectPersonByIrnAndType(
        props.personsWithName,
        props.student.irn,
        studentPersonType
      );
      if (studentPerson) {
        setStudentPersonId(studentPerson.id);
        setStudentFullName(
          `${studentPerson.firstName} ${studentPerson.lastName}`
        );
        return;
      }
    } else {
      setStudentPersonId(null);
      setStudentFullName("");
    }
  }, [studentIrnList]); // eslint-disable-line react-hooks/exhaustive-deps
  // =========================================================================================================================

  // =========================================================================================================================
  // Iterating through get Acknowledgement endpoint, personNames list, studentIrnList, current Courses list, and SRM endpoint.
  const createStudentObject = (
    studentData,
    studentKey,
    personsWithName,
    facultyDemographics,
    isNew = false
  ) => {
    return {
      studentAccommodationInfoId:
        studentData?.studentAccommodationInfoId || "0",
      studentIrn: personsWithName.personsWithNameMap[studentKey].issuerId,
      studentPersonId: personsWithName.personsWithNameMap[studentKey].id,
      fullName: `${personsWithName.personsWithNameMap[studentKey].firstName} ${personsWithName.personsWithNameMap[studentKey].lastName}`,
      notes: studentData?.notes || "",
      isFinalGradePosted: studentData?.isFinalGradePosted || false,
      dateCreated: studentData?.dateCreated || "",
      createdBy: studentData?.createdBy || "",
      dateModified: studentData?.dateModified || "",
      modifiedBy: studentData?.modifiedBy || "",
      facultyIrn: facultyDemographics.issuerId,
      facultyPersonId: facultyDemographics.personId,
      courseOfferingId: studentData?.courseOfferingId || "0",
      newAccommodation: isNew,
      receivingFromFwsDatabase: !!studentData,
      facultyAcknowledgements: studentData?.facultyAcknowledgements || [],
    };
  };

  const createCourseObject = (course) => ({
    courseId: course.courseId,
    courseTitle: course.courseTitle,
    groupId: course.groupId,
    startDate: course.startDate,
    endDate: course.endDate,
    offeringType: course.offeringType,
    courseOfferingId: course.courseOfferingId,
  });

  useEffect(() => {
    const { issuerId: facultyIssuerId } = props.facultyDemographics;
    const { personsWithNameMap } = props.personsWithName;

    if (
      !facultyIssuerId ||
      !Object.keys(personsWithNameMap).length ||
      !studentIrnList.length ||
      Object.keys(personsWithNameMap).length !== studentIrnList.length
    ) {
      return;
    }

    const tempCourses = allCoursesToDisplay.map((course) => {
      const courseObj = createCourseObject(course);
      const courseStudentIrnList = course.accommodatedStudentIrnList || [];

      const studentList = courseStudentIrnList.map((studentIrn) => {
        const studentKey = `${studentIrn}-student`;
        const matchingAccommodation = props.studentAccommodations.find(
          (acc) =>
            `${acc.studentIrn}` === `${studentIrn}` &&
            (acc.courseOfferingId === 0 || acc.courseOfferingId !== 0)
        );

        return createStudentObject(
          matchingAccommodation,
          studentKey,
          props.personsWithName,
          props.facultyDemographics,
          !matchingAccommodation || matchingAccommodation.courseOfferingId === 0
        );
      });

      return { ...courseObj, studentList };
    });

    setCourses(tempCourses);
  }, [
    props.personsWithName,
    props.studentAccommodations,
    currentCourses,
    props.facultyDemographics,
    update,
  ]); // eslint-disable-line

  return (
    <ThemeProvider theme={facultyPortalTheme}>
      <Box
        className={
          matches
            ? classes.pageContainer
            : tabletMatches
            ? classes.pageContainerTablet
            : classes.pageContainerMobile
        }
      >
        <Grid
          item
          className={
            matches
              ? classes.linkContainer
              : tabletMatches
              ? classes.linkContainerTablet
              : classes.linkContainerMobile
          }
        >
          <Link
            id="backToDashboardLink"
            data-testid="back_to_dashboard_link"
            underline="none"
            className={classes.backToDashboardLink}
            href="/"
          >
            <img className={classes.arrowImage} src={BlackArrow} alt="" />
            <Typography>{`Course Dashboard`}</Typography>
          </Link>
        </Grid>
        <div
          style={{
            maxWidth: "1232px", // Maximum width for large screens
            width: "86%", // Leaves a 7% margin on each side (100% - 2 * 7%)
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "8%",
              marginTop: "1%",
            }}
          >
            <Typography
              id="pageHeader"
              data-testid="page_header"
              component="h1"
              className={
                matches
                  ? classes.pageHeader
                  : tabletMatches
                  ? classes.pageHeaderTablet
                  : classes.pageHeaderMobile
              }
            >
              {"Manage accommodations"}
            </Typography>
          </Box>
          <Box className={classes.mainContentContainer}>
            <Typography
              component="p"
              className={classes.newFont}
              style={{ marginBottom: "24px" }}
            >
              {`University of Phoenix is required to prohibit discrimination and to provide reasonable accommodations and modifications to qualified students in all University programs and activities under the law.`}
            </Typography>
            <Typography component="p" className={classes.newFont}>
              {`As faculty of University of Phoenix, you are responsible for implementing the approved accommodations for students in class. Accommodations are determined after a deliberative and interactive process between the student and University staff. Accommodations must be provided as indicated, following all guidelines and directives provided in the accommodation notification.`}
            </Typography>
          </Box>
          {courses.length > 0 ? (
            courses.map((course) => (
              <CourseTableCard
                course={course}
                setAlreadyAcknowledged={setAlreadyAcknowledged}
                setOpenModal={setOpenModal}
                setActionNeededObject={setActionNeededObject}
                actionNeededObject={actionNeededObject}
                update={update}
              />
            ))
          ) : (
            <>
              <TableCell colSpan={6} className={classes.emptyStateContainer}>
                <Typography className={classes.noStudentsText}>
                  You currently do not have any accommodated students.
                </Typography>
              </TableCell>
            </>
          )}
        </div>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        disableEscapeKeyDown // This disables closing the modal with the Escape key
        className={classes.actionModal}
      >
        <div className={classes.modalContent}>
          <ActionNeeded
            alreadyAcknowledged={alreadyAcknowledged}
            setOpenModal={setOpenModal}
            actionNeededObject={actionNeededObject}
            update={update}
            setUpdate={setUpdate}
          />
        </div>
      </Modal>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    facultyDemographics: state.demographics,
    facultyContacts: state.facultyContacts,
    studentAccommodations: state.studentAccommodations,
    personsWithName: state.personsWithName,
    srmStudentAccommodations: state.studentApiAccommodations,
    extendedCourses: state.extendedCourses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStudentAccommodations: (getAcknowledgentObject) =>
      dispatch(getStudentAccommodations(getAcknowledgentObject)), //Retrieving accommodations from FWS
    getPersonInfoWithName: (irn, personType) =>
      dispatch(getPersonInfoWithName(irn, personType)), //Retrieving student names
    getExtendedCoursesForIrn: (irn) => dispatch(getExtendedCoursesForIrn(irn)), //Retrieving extended courses
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAccommodations);
