import { makeStyles } from "@material-ui/core";

export default makeStyles({
  // Desktop View
  desktopCard: {
    marginBottom: "20px",
    fontFamily: "Roboto",
    // maxWidth: "609px",
  },
  desktopCardContent: {
    margin: "12px 12px 20px 12px",
  },
  desktopTitle: {
    fontWeight: "700 !important",
    fontSize: "24px !important",
    color: "#000304 !important",
  },
  descriptionBoxContainer: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  descriptionAndButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  descriptionAndButtonMobileContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    //display: "flex",
    justifyContent: "start",
    width: "100%",
  },
  button: {
    marginTop: "16px",
    marginBottom: "16px",
    width: "100%",
  },
  buttonMobile: {
    marginTop: "16px",
    marginBottom: "16px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  facultyWorkshop: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: "16px",
  },
  facultyWorkshopIcon: {
    marginRight: "8px",
  },
  dashboardDescriptionAndButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
  },
  facultyWorkshopText: {
    margin: "20px 0px -20px 0px",
    color: "#DC3727",
  },
  workshopMaterialsLink: {
    marginTop: "30px",
    color: "#DC3727",
  },
  workshopDescription: {
    marginTop: "16px",
    marginBottom: "-16px",
    textAlign: "justify",
  },
  extTitle: {
    marginTop: "57px",
    marginBottom: "15px",
    fontSize: "32px",
    fontWeight: "400",
    lineHeight: "40px"
  },

  // Mobile View
  mobileCard: {
    minWidth: "327px",
    //width: "327px",
    marginBottom: "21px",
  },
  mobileCardContent: {
    margin: "0 5px",
  },
  mobileTitle: {
    fontWeight: "700 !important",
    fontSize: "21px !important",
    color: "#000304 !important",
  },
  mobileButton: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  mobileFacultyWorkshoptext: {
    margin: "16px 0px 8px 0px",
    color: "#DC3727",
  },
  descriptionBoxContainerMobile: {
    marginTop: "15px",
  },
  workshopDescriptionMobile: {
    marginTop: "8px",
    marginBottom: "-48px",
    textAlign: "justify",
  },
  workshopMaterialsMobileLink: {
    marginTop: "15px",
    marginBottom: "15px",
    color: "#DC3727",
  },
  mobileDashboardDescriptionAndButtonContainer: {
    margin: "12px 0 5px 0",
  },
  // Universal for both Desktop and Mobile View
  show: {
    display: "block",
    marginTop: "16px",
  },
  hide: {
    display: "none",
  },
  infoBoxMobile: {
    margin: "2px 2px",
  },
  extTitleMobile: {
    width: "327px",
    display: "flex",
    justifyContent: "flex-start",
    margin: "50px 0px 10px 0px",
    fontSize: "24px",
    fontWeight: "400",
   //lineHeight: "32px"
  },
});
